.gridY {
  /* NOTE: this is the width of the grid without the padding on either side, This mus match <Wrapper> */
  --grid-width: calc(100vw - var(--site-padding-mobile-full));
  /* 8 columns for mobile */
  --grid-column-width: calc(var(--grid-width) / 8);

  /* NOTE: a unit is one grid square. So unit-3 is three grid squares */
  --grid-unit-1: var(--grid-column-width);
  --grid-unit-2: calc(var(--grid-column-width) * 2);
  --grid-unit-3: calc(var(--grid-column-width) * 3);
  --grid-unit-4: calc(var(--grid-column-width) * 4);
  --grid-unit-6: calc(var(--grid-column-width) * 6);
  --grid-unit-7: calc(var(--grid-column-width) * 7);

  --grid-color: rgba(125, 125, 125, 0.4);
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    var(--grid-color) 1px,
    transparent 1px
  );
  background-size: var(--grid-column-width) var(--grid-column-width);
  background-repeat: repeat;
}

.gridX {
  width: 100%;
  background-image: linear-gradient(
    to right,
    var(--grid-color) 1px,
    transparent 1px
  );
  background-size: calc((var(--grid-width) - 1px) / 8) auto;
}

@screen laptop {
  .gridY {
    --grid-width: calc(100vw - var(--site-padding-laptop-full));
    /* 12 columns for dektop */
    --grid-column-width: calc(var(--grid-width) / 12);
  }
  .gridX {
    background-size: calc((var(--grid-width) - 1px) / 12) auto;
  }
}
